import { apiGet } from '../../utils/ApiUtils';
import {
  DeliveryAreaReadDTO, getAddress,
  MenuItemGroupMinimalReadDTO,
  MenuItemWriteDTO, OpenStreetMapAddressResult,
  SkuIdWrapperDTO,
  ToppingGroupMinimalReadDTO
} from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import { useOrsysContext } from '../../utils/OrsysContext';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const deliveryAreaGlobalKey = ['deliveryArea'];

export const deliveryAreaByIdKey = (id: number | null) => ['deliveryArea', 'by-id', `${id}`];

export function useDeliveryAreaByIdQuery(
  id: number | null,
  {
    options
  }: {
    options?: UseQueryOptions<DeliveryAreaReadDTO | null>;
  } = {}
) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    initialData: null,
    gcTime: 1000 * 60 * 5,
    queryKey: deliveryAreaByIdKey(id),
    queryFn: async () => {
      const { data: response } = await apiGet<DeliveryAreaReadDTO>(`/delivery-area/${id}`);
      response.deliveryZones?.sort((a, b) => a.sortingNumber - b.sortingNumber);
      return response;
    },
    enabled: user?.id != null && !!id
  });
}

export const restaurantAddressDataByDeliveryAreaIdKey = (id: number | null) => ['restaurant', 'addressData', 'by-id', `${id}`];

export function useRestaurantAddressDataQuery(
  areaId: number | null,
  {
    options
  }: {
    options?: UseQueryOptions<OpenStreetMapAddressResult | null>;
  } = {}
) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    initialData: null,
    gcTime: 1000 * 60 * 5,
    queryKey: restaurantAddressDataByDeliveryAreaIdKey(areaId),
    queryFn: async () => {
      const { data: response } = await apiGet<DeliveryAreaReadDTO>(`/delivery-area/${areaId}`);

      const addressText = response.restaurant?.locationAddress ? encodeURIComponent(getAddress(response.restaurant?.locationAddress)) : 'Budapest';

      const searchResult = await fetch(`https://nominatim.openstreetmap.org/search?q=${addressText}&format=json&polygon=1&addressdetails=1`);

      const data: OpenStreetMapAddressResult[] = await searchResult.json();

      let address;

      if (data.length > 0) {
        address = data[0];
      }

      return address ?? null;
    },
    enabled: user?.id != null && !!areaId
  });
}
