import { useParams, useSearchParams } from 'next/navigation';
import { Url } from 'next/dist/shared/lib/router/router';

export function useLinkUtil() {
  const queryParams = useSearchParams();
  const params = useParams();
  const parseLink = (newPathName: Url, options?: {
    queryParamsToAppend?: {
      [key: string]: string | number | boolean | null | undefined | string[] | number[]
    },
    queryParamsToRemove?: string[],
    clearQueryParams?: boolean
  }) => {
    const query = new URLSearchParams(options?.clearQueryParams ? '' : queryParams.toString());
    if (options?.queryParamsToAppend) {
      Object.keys(options?.queryParamsToAppend).forEach((key) => {
        query.delete(key);
        if (options?.queryParamsToAppend![key]) {
          if (Array.isArray(options?.queryParamsToAppend![key])) {
            for (const keyElement of options?.queryParamsToAppend![key] as any[]) {
              query.append(key, keyElement!.toString());
            }
          } else {
            query.append(key, options?.queryParamsToAppend![key]!.toString());
          }

        }
      });
    }
    for (const key of options?.queryParamsToRemove ?? []) {
      query.delete(key);
    }
    query.sort();

    console.log(`navigate to: ${newPathName}?${query.toString()}${window.location.hash}`);
    return `${newPathName}?${query.toString()}${window.location.hash}`;
  };

	return { parseLink };
}
